import React from "react";

const Pricing = () => {
  return (
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        <div className="row justify-content-center">
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Essential</p>
              <h2 className="font-size-11 mb-1">€395</h2>
              <span className="font-size-5 mb-2">€120 yearly renewal</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-3">Aurelius</li>
                <li className="mb-3">Sparkle</li>
                <li className="mb-3">XData</li>
                <li className="mb-3">Logging</li>
                <li className="mb-3 text-red"><del>Data Modeler</del></li>
                <li className="mb-3 text-red"><del>RemoteDB</del></li>
                <li className="mb-3 text-red"><del>Scripter</del></li>
                <li className="mb-3 text-red"><del>Sphinx</del></li>
                <li className="mb-3 text-red"><del>Echo</del></li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="/#">
                  Buy now
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Standard</p>
              <h2 className="font-size-11 mb-1">€495</h2>
              <span className="font-size-5 mb-2">€150 yearly renewal</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
              <li className="mb-3">Aurelius</li>
                <li className="mb-3">Sparkle</li>
                <li className="mb-3">XData</li>
                <li className="mb-3">Logging</li>
                <li className="mb-3">Data Modeler</li>
                <li className="mb-3">RemoteDB</li>
                <li className="mb-3">Scripter</li>
                <li className="mb-3 text-red"><del>Sphinx</del></li>
                <li className="mb-3 text-red"><del>Echo</del></li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="/#">
                  Buy now
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={900}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Premium</p>
              <h2 className="font-size-11 mb-1">€595</h2>
              <span className="font-size-5 mb-2">€180 yearly renewal</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-3">Aurelius</li>
                <li className="mb-3">Sparkle</li>
                <li className="mb-3">XData</li>
                <li className="mb-3">Logging</li>
                <li className="mb-3">Data Modeler</li>
                <li className="mb-3">RemoteDB</li>
                <li className="mb-3">Scripter</li>
                <li className="mb-3">Sphinx</li>
                <li className="mb-3">Echo</li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="/#">
                  Buy now
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
